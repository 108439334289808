import * as React from "react"

export default function Mudhorobah () {
    return (
        <>
            <p>
                Bentuk kerjasama yang dimana islah menberikan modal kepada penjual serta ikut terjun dalam pengelolaan si penjual, dan keuntungan seseuai kesepakatan dengan minimal 50% untuk islah 
            </p>
            <p>
                nb: dalam jangka waktu yang disepakati mau penjual rugi atau laba, penjual harus mengembalikan modal yang diberikan islah kepada penjual:)
            </p>
        </>
    )
}